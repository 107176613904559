export const gateway = "https://sterling-api.link.wawanesalife.com";
export const googleApiKey = "AIzaSyD8GAXLH7FxUmF13tfHgXVAU6fWk66UH3k";
export const brokerConfig = {
	enabled: "yes",
	authorized_nb: "yes",
	authorized_mb: "yes",
	authorized_pe: "yes",
	authorized_yt: "yes",
	authorized_sk: "yes",
	authorized_qc: "no",
	authorized_nt: "yes",
	authorized_nu: "yes",
	authorized_ns: "yes",
	authorized_nl: "yes",
	authorized_on: "yes",
	authorized_ab: "yes",
	authorized_bc: "yes",
	"split": '0.6'
};
export const brokerBranding = {
	"sterling.link.wawanesalife.com": {
		a: {
			broker: "sterling",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://www.iubenda.com/privacy-policy/90666380/full-legal",
			scope: "sterling#branding#sterling.link.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "info@sterlingbrokers.com",
			video_link: "null",
			map2: "Suite 200 84 Chain Lake Drive,Halifax,NS,B3S 1A2",
			environment_id: "stable",
			map1: "48-209 Wicksteed Avenue,Toronto,ON,M4G 0B1",
			brands: "sterling.link.wawanesalife.com",
			map4: "Unit 110 4919-59 Street,Red Deer,AB,T4P 1M8",
			map3: "Suite 1501 1111 West Georgia St.,Vancouver,BC,V6E 4M3",
			introduction: "null",
			phone: "1-888-213-5557",
			menuPhone: "button",
			environment: "stable",
			website: "https://www.sterlingbrokers.com/",
			ftcLink: "null",
			layout: "bohemia",
			g4: "G-BKLFX8DNNL",
			photo3: "hide",
			photo4: "hide",
			photo1: "hide",
			photo2: "hide",
			tagline: "A world-class client experience",
			approve_user: "link_sterling",
			logoPosition: "menu",
			ab: "a",
			logo: "a.png",
			brand_name: "sterling.link.wawanesalife.com",
		},
		b: {
			broker: "sterling",
			accessibilityLink: "null",
			themeSwitcher: "hide",
			privacyLink: "https://www.iubenda.com/privacy-policy/90666380/full-legal",
			scope: "sterling#branding#sterling.link.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "info@sterlingbrokers.com",
			video_link: "null",
			map2: "Suite 200 84 Chain Lake Drive,Halifax,NS,B3S 1A2",
			environment_id: "stable",
			map1: "48-209 Wicksteed Avenue,Toronto,ON,M4G 0B1",
			brands: "sterling.link.wawanesalife.com",
			map4: "Unit 110 4919-59 Street,Red Deer,AB,T4P 1M8",
			map3: "Suite 1501 1111 West Georgia St.,Vancouver,BC,V6E 4M3",
			introduction: "null",
			phone: "1-888-213-5557",
			menuPhone: "button",
			environment: "stable",
			website: "https://www.sterlingbrokers.com/",
			ftcLink: "null",
			layout: "bohemia",
			g4: "G-BKLFX8DNNL",
			photo3: "hide",
			photo4: "hide",
			photo1: "hide",
			photo2: "hide",
			tagline: "A world-class client experience",
			approve_user: "link_sterling",
			logoPosition: "menu",
			ab: "b",
			logo: "b.png",
			brand_name: "sterling.link.wawanesalife.com",
		},
	},
};

//updated on Fri Jun 07 2024 19:41:09 GMT-0700 (Pacific Daylight Time)
